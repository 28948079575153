import React, { useState, useRef, useEffect } from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import Messaggio from "../componenti/messaggio";
import logo from "../immagini/logo_saep.svg";
import be from "../lib/backend.jsx";
import "../style/style.css";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const [remainingCredits, setRemainingCredits] = useState(5);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isReplying, setIsReplying] = useState(false); // Variaile che uso per capire se sta ancora rispondendo e non permetto di inviare altre richieste
  const [isThinking, setIsThinking] = useState(false);
  const [bottomClicked, setBottomClicked] = useState(false);
  const [isInFirstEightyPercent, setIsInFirstEightyPercent] = useState(false);
  const [suggFlag, setSuggFlag] = useState(false);
  // const [stopReplying, setStopReplying] = useState(false);
  // const [isVisible, setIsVisible] = useState(false); // Track visibility
  // const [scrolling, setScrolling] = useState(false);

  const stringaRef = useRef("");
  const messagesEndRef = useRef(null); // Variabile del messaggio per lo scroll (riferimento)
  const aiMessageIndexRef = useRef(null); // Track the index of the current AI message being updated
  const buttonBottom = useRef(null);
  const messageSectionRef = useRef(null);

  const isSmall = useMediaQuery({ query: `(max-width: 1024px)` });

  const checkIfInFirstEightyPercente = () => {
    const container = messageSectionRef.current;
    const scrollPercentage =
      (container.scrollTop + container.clientHeight) / container.scrollHeight;
    if (container.scrollHeight > container.clientHeight) {
      setIsInFirstEightyPercent(scrollPercentage < 0.8);
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage("enter");
    }
  };

  const sendSuggestionMessage = (e) => {
    setInputMessage(e.target.innerText);
    setSuggFlag(true);
  };

  // To scroll to the bottom of the chat
  useEffect(() => {
    if (inputMessage.trim() !== "") {
      sendMessage("click");
    }
  }, [suggFlag]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [messages]);

  useEffect(() => {
    if (isThinking) {
      setMessages((messages) => {
        aiMessageIndexRef.current = messages.length; // the index of the last message to be appended.
        return [
          ...messages,
          { content: "Sto pensando...", type: "ai-message" },
        ];
      });
    }
  }, [isThinking]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      setBottomClicked(false);
    }
  }, [bottomClicked]);

  // To stream the be response
  useEffect(() => {
    // go-to-bottom-button
    const container = messageSectionRef.current;
    container.addEventListener("scroll", checkIfInFirstEightyPercente);

    // handling the chat response
    function handleChatResponse(data) {
      // if (stopReplying) {  // TODO: da una quai part gu da met stopReplying a falseeeeee
      //   setIsReplying(false);
      //   return
      // }

      if (data.message.first) {
        // setScrolling(false);
        setMessages((messages) => messages.slice(0, -1)); // Remove the last message (Sto pensando...)
        stringaRef.current = data.message.content; // Reset stringaRef when the first part of the message is received.
        setMessages((messages) => {
          aiMessageIndexRef.current = messages.length; // the index of the last message to be appended.
          return [
            ...messages,
            {
              content: stringaRef.current,
              type: "ai-message",
              remainingCredits: data.credits,
              doneWriting: false,
            },
          ];
        });
      } else {
        // appendo un chunk alla volta nel box del messaggio
        setIsThinking(false);
        stringaRef.current += data.message.content; // Append the content to stringaRef.
        setMessages((messages) => {
          const updatedMessages = [...messages];
          updatedMessages[aiMessageIndexRef.current].content =
            stringaRef.current;
          return updatedMessages;
        });
      }

      if (data.message.last) {
        // console.log(lastAIMessage.current)
        // lastAIMessage.current.classList.remove('invisible');
        setMessages((messages) => {
          const updatedMessages = [...messages];
          updatedMessages[messages.length - 1] = {
            ...updatedMessages[messages.length - 1],
            doneWriting: true,
          };
          return updatedMessages;
        });
        setIsReplying(false);
      }
    }

    // handling the error event
    function errorMessage(data) {
      setMessages((messages) => messages.slice(0, -1)); // Remove the last message (Sto pensando...)
      setIsReplying(false);
      setIsThinking(false);
      toast("Errore: " + data.content, {
        icon: () => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>
        ),
      });
    }

    // Register the 'chat_response' listener once
    be.on("chat_response", handleChatResponse);
    be.on("error", errorMessage);

    // Clean up the listener when the component unmounts
    return () => {
      be.off("chat_response", handleChatResponse);
      be.off("error", errorMessage);
    };
  }, []);

  function logout() {
    window.location.href = "https://www.saep.it/";
  }

  function refresh() {
    window.location.reload();
  }

  function copyChat() {
    var chatCopied = "";
    if (messages.length === 0) {
      toast("Chat vuota!", {
        icon: () => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
            />
          </svg>
        ),
      });
    } else {
      messages.forEach((item) => {
        if (item.type === "ai-message") {
          chatCopied += "[ELi]: " + item.content + "\n";
        } else if (item.type === "human-message") {
          chatCopied += "[User]: " + item.content + "\n";
        }
      });
      navigator.clipboard.writeText(chatCopied);
      toast("Testo copiato!", {
        icon: () => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        ),
      });
    }
  }

  function sendMessage(pressType) {
    // Se sta rispondendo e ho premuto di nuovo invio blocco l'append.
    if (isReplying || isThinking) {
      if (pressType === "click") {
        be.emit("stop");
        setIsReplying(false);
        setIsThinking(false);
      }
    } else {
      if (remainingCredits > 0) {
        if (inputMessage.trim() !== "") {
          setIsReplying(true);
          setIsThinking(true);
          setMessages((messages) => [
            ...messages,
            { content: inputMessage, type: "human-message" },
          ]);
          setRemainingCredits(remainingCredits - 1);
          if (remainingCredits === 1) {
            toast("Hai terminato i crediti a tua disposizione.", {
              icon: () => (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                  />
                </svg>
              ),
            });
          }

          be.emit("send_message", { msg: inputMessage });
          setInputMessage("");
        }
      } else {
        document.getElementById("modalCredits").showModal();
      }
    }
  }

  // function setVh() {
  //   let vh = window.innerHeight * 0.01; // 1% of the viewport height
  //   document.documentElement.style.setProperty('--vh', `${vh}px`);
  // }

  // window.addEventListener('resize', setVh);

  return (
    <>
      <whole-page>
        <nav>
          <div className="main-logo-cont">
            <img
              title="Esci da ELi"
              alt=""
              src={logo}
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("modalLogout").showModal();
              }}
            />
          </div>
          <div className="buttons-cont">
            <div
              title="Copia la chat intera"
              className="copy-chat-button"
              onClick={(e) => {
                e.preventDefault();
                copyChat();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.7"
                stroke="#568fdb"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                />
              </svg>
            </div>
            <div
              title="Ricarica la pagina"
              className="refresh-button"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("modalRefresh").showModal();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.7"
                stroke="#568fdb"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </div>
            <div
              title="Esci da ELi"
              className="exit-button"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("modalLogout").showModal();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.7"
                stroke="#568fdb"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                />
              </svg>
            </div>
          </div>
        </nav>
        <chat-section>
          <messages-section ref={messageSectionRef}>
            {messages.length > 0 ? (
              messages.map((item, index) => {
                return (
                  <Messaggio
                    key={index}
                    type={item.type}
                    content={item.content}
                    remainingCredits={item.remainingCredits}
                    doneWriting={item.doneWriting}
                  />
                );
              })
            ) : (
              <div className="pageEmptyCont">
                <div className="introCont">
                  <p className="introTitle"> Ciao, sono ELi </p>
                  <p className="introSubTitle">
                    {" "}
                    Oggi ti suggerisco questi argomenti{" "}
                  </p>
                </div>
                <div className="suggestionsCont">
                  <div
                    onClick={sendSuggestionMessage}
                    className="suggestionsItem"
                  >
                    SAEP offre servizi di Business Intelligence?
                  </div>
                  <div
                    onClick={sendSuggestionMessage}
                    className="suggestionsItem"
                  >
                    Parlami della migrazione ACG nel vostro ambito.
                  </div>
                  {!isSmall && (
                    <div
                      onClick={sendSuggestionMessage}
                      className="suggestionsItem"
                    >
                      Quali vantaggi porta avere un unico software integrato?
                    </div>
                  )}
                  {!isSmall && (
                    <div
                      onClick={sendSuggestionMessage}
                      className="suggestionsItem"
                    >
                      Qual è il valore aggiunto di adottare l'ERP SAEP?
                    </div>
                  )}
                </div>
              </div>
            )}
            <div ref={messagesEndRef} className="referenceBottomChat" />
          </messages-section>
          <input-section>
            {isInFirstEightyPercent && (
              <button
                className="go-to-bottom-button"
                ref={buttonBottom}
                onClick={(e) => {
                  e.preventDefault();
                  setBottomClicked(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#568fdb"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            )}
            <input-box>
              <div className="textarea-cont">
                <textarea
                  name=""
                  id=""
                  placeholder="Scrivi un messaggio a ELi..."
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  onKeyDown={handleEnter}
                />
              </div>
              <div className="buttons">
                <div title="Crediti rimanenti" className="crediti-cont">
                  🪙 {remainingCredits}{" "}
                </div>
                <div> | </div>
                <div
                  className="send-button"
                  onClick={(e) => {
                    e.preventDefault();
                    sendMessage("click");
                  }}
                >
                  {isReplying ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6-stop"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </input-box>
          </input-section>
          <footer>
            <a
              href="https://www.saep.it/faq/"
              target="_blank"
              rel="noopener noreferrer"
            >
              info
            </a>
            <a
              href="https://www.iubenda.com/privacy-policy/50130093"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy
            </a>
            <a
              href="https://www.saep.it/contatti/"
              target="_blank"
              rel="noopener noreferrer"
            >
              contatti
            </a>
          </footer>
        </chat-section>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Slide}
        />
      </whole-page>

      <dialog id="modalLogout" className="modal-logout">
        <form method="dialog">
          <p className="titleDial"> Abbandona questa pagina </p>
          <div className="divider" />
          <div>
            <p className="txtDial">
              {" "}
              Cliccando sul tasto "Esci" verrai reindirizzato al sito di SAEP.
              Sei sicuro di voler continuare?{" "}
            </p>
            <div>
              <button className="button-confirm" onClick={() => logout()}>
                {" "}
                Esci{" "}
              </button>
              <button className="button-cancel"> Annulla </button>
            </div>
          </div>
        </form>
      </dialog>

      <dialog id="modalRefresh" className="modal-refresh">
        <form method="dialog">
          <p className="titleDial"> Ricarica </p>
          <div className="divider" />
          <div>
            <p className="txtDial"> Sei sicuro di voler ricarica la pagina? </p>
            <div>
              <button className="button-confirm" onClick={() => refresh()}>
                {" "}
                Ricarica{" "}
              </button>
              <button className="button-cancel"> Annulla </button>
            </div>
          </div>
        </form>
      </dialog>

      <dialog id="modalCredits" className="modal-credits">
        <form method="dialog">
          <p className="titleDial"> Crediti Esauriti </p>
          <div className="divider" />
          <div>
            <p className="txtDial">
              {" "}
              I crediti a tua disposizione sono terminati.{" "}
            </p>
            <p className="txtDial">
              {" "}
              Ricaricando la pagina i crediti verranno ricaricati e la chat
              verra svuotata.{" "}
            </p>
            <div>
              <button className="button-confirm" onClick={() => refresh()}>
                {" "}
                Ricarica{" "}
              </button>
              <button className="button-cancel"> Annulla </button>
            </div>
          </div>
        </form>
      </dialog>
    </>
  );
};

export default Home;
