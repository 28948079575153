import { io } from "socket.io-client";

// prod
const SERVER_IP_STRING = "https://eli.saep-ict.it";

// test
// const SERVER_IP_STRING = "https://eli-test.saep-ict.it";

// local
// const SERVER_IP_STRING = "http://localhost:5000";

const socket = io(SERVER_IP_STRING);

export default socket;
