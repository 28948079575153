import React, { useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import be from '../lib/backend.jsx'
import '../style/style.css';

const Messaggio = ({ type, content, remainingCredits, doneWriting }) => {

  const likeRef = useRef(null)
  const dislikeRef = useRef(null)

  function functLike() {
    console.log(remainingCredits);
    dislikeRef.current.classList.remove('clicked');
    likeRef.current.classList.add('clicked');
    be.emit('send_feedback', { qa_id: remainingCredits, conversation_id: be.id, text: "like" });
  }

  function functDislike() {
    console.log(remainingCredits);
    dislikeRef.current.classList.add('clicked');
    likeRef.current.classList.remove('clicked');
    be.emit('send_feedback', { qa_id: remainingCredits, conversation_id: be.id, text: "dislike" });
  }

  return (
    <message-cont>
      <ReactMarkdown className={type}>{content}</ReactMarkdown>
      {type === "ai-message" && doneWriting && <div className='feedback-container'>
        <svg onClick={(e) => { e.preventDefault(); functLike() }} ref={likeRef} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#888888" className="size-6">
          <title> Like </title>
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 15.182a4.5 4.5 0 0 1-6.364 0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
        </svg>
        <svg onClick={(e) => { e.preventDefault(); functDislike() }} ref={dislikeRef} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#888888" className="size-6">
          <title> Dislike </title>
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 16.318A4.486 4.486 0 0 0 12.016 15a4.486 4.486 0 0 0-3.198 1.318M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
        </svg>
        {/* <svg onClick={(e) => { e.preventDefault(); functDislike(e) }} version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet" className="size-6">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1440 4959 c-102 -20 -221 -83 -306 -161 -91 -84 -182 -238 -224 -378 -10 -36 -105 -451 -210 -922 -207 -936 -209 -945 -170 -1069 66 -216 274 -392 522 -443 62 -13 146 -16 428 -16 192 0 350 -2 350 -4 0 -3 -29 -63 -65 -134 -202 -403 -274 -754 -210 -1029 72 -310 320 -532 702 -629 116 -29 142 -30 194 -4 48 25 75 67 89 140 63 320 163 612 281 822 108 190 240 343 464 537 239 208 384 362 454 484 l32 56 287 3 287 3 55 28 c76 39 143 108 177 181 l28 61 0 955 0 955 -32 66 c-39 79 -102 140 -181 177 -56 27 -61 27 -319 32 l-261 5 -42 52 c-52 66 -145 137 -235 179 -140 65 -126 64 -1137 63 -502 -1 -933 -5 -958 -10z m1910 -325 c29 -9 69 -26 89 -38 47 -30 114 -104 140 -154 11 -22 37 -51 58 -63 37 -24 43 -24 333 -27 189 -2 299 -7 307 -14 18 -15 19 -1778 1 -1796 -9 -9 -91 -12 -308 -12 -162 0 -313 -5 -334 -10 -60 -13 -88 -39 -128 -120 -86 -169 -141 -232 -423 -480 -200 -176 -246 -221 -340 -334 -138 -165 -287 -432 -369 -661 -38 -106 -99 -315 -111 -378 -4 -20 -12 -37 -18 -37 -6 0 -53 20 -104 45 -266 129 -346 359 -253 720 52 201 164 445 315 690 79 127 88 168 53 235 -17 33 -35 51 -68 68 -44 22 -46 22 -546 22 -464 0 -507 1 -563 19 -149 46 -251 151 -251 258 0 49 363 1684 396 1785 46 141 126 238 229 278 48 18 86 19 946 19 789 1 902 -1 949 -15z" />
          </g>
        </svg> */}
      </div>}
    </message-cont>
  );
};

export default Messaggio;